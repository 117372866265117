// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---plugins-gatsby-plugin-offline-app-shell-js": () => import("/Users/majid/Projects/Personal/pwawithangular.com/plugins/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---plugins-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("/Users/majid/Projects/Personal/pwawithangular.com/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/majid/Projects/Personal/pwawithangular.com/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/majid/Projects/Personal/pwawithangular.com/.cache/data.json")

